@import 'style';
body, html{
	min-width: 1350px !important;
	overflow: visible;
}

.app-bar-container{
	.menu{
		display: none;
	}
	.logo{
		margin-left: -100px;
		position: relative;
		top: 0;
		left: 50%;
		padding: 35px 0;
		width: 200px;
		height: 45px;
		background: url(/assets/images/desktop-logo.png) no-repeat;
		background-position: center;
		background-size: contain;
		text-indent: -9999px;
		display: inline-block;
		min-width: 250px;
		left: 48% !important;
	}
}

nav#main-navigation{
	background: none;
	height: auto;
	position: fixed;
	top: 0;
	width: auto;
	z-index: 500;
	transform: translate(0, 0);

	h4{
		display: none;
	}

	ul{
		padding: 0;
		margin: 9px 25px 0;
		display: block;
		width: 100%;
		font-size: 15px;

		li{
			list-style-type: none;
			display: inline-block;
			border-bottom: none;
			padding: 0 15px 0;

			&.active{
				border-bottom: 4px solid $colorGray;
				transition: all 0.2s ease-in-out;
			}

			&:hover{
				border-bottom: 4px solid $colorGray;
				transition: all 0.2s ease-in-out;
			}

			a{
				padding: 17px 0 4px;
				border-bottom: 4px solid trasparent;
				transition: all 0.4s ease-in-out;
			}
		}
	}
}

#main{
	position: relative;
	padding-bottom: 100px;
}

.app-bar-container, #header{
	min-width: 1350px;
}
#clear{
	display: none;
}

.slogan{
	padding-top: 200px;
}
.blocks{
	min-width: 1320px !important;
}

.content-block{
	padding: 450px 0;
}

a{
	.next-slide, .previous-slide{
		display: none !important;

		&.first{
			display: none !important;
		}
	}
}
.next-slide, .previous-slide{
	display: none !important;

	&.first{
		display: none !important;
	}
}

.owl-carousel{
	margin-bottom: -50px;
}

.owl-nav{
	right: 0;
	z-index: 60000 !important;
}
.item{
 	padding: 400px 0;
 }